import axios from "axios";
import React, { useEffect, useState } from "react";

const MaterialManager = () => {
  const [materials, setMaterials] = useState([]);
  const [dependentMaterials, setDependentMaterials] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [repairStyleId, setRepairStyleId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [repairStyles, setRepairStyles] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedRepairStyleId, setSelectedRepairStyleId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  useState(null);
  const [material, setMaterial] = useState({
    name: { az: "", en: "", ru: "" },
    description: { az: "", en: "", ru: "" },
    categories: [{ id: null }],
    repairStyles: [{ id: null }],
    unitOfMeasure: "",
    measureCapacity: "",
    usagePerSquareMeter: "",
    lowPrice: "",
    midPrice: "",
    highPrice: "",
    servicePrice: "",
    currency: "AZN",
    image: { id: null },
    imageOnRoom: { id: null },
    isSelectable: true,
    dependentMaterials: [{ id: null }],
    status: true,
  });
  const authToken = localStorage.getItem("authToken");
  useEffect(() => {
    fetchCategories();
    fetchRepairStyles();
  }, []);

  const fetchMaterials = (categoryId, repairStyleId) => {
    if (categoryId && repairStyleId) {
      axios
        .get(
          `https://api.emotix.dev/api/v1/e-prorab/materials/filter?categoryId=${categoryId}&repairStyleId=${repairStyleId}`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        )
        .then((response) => {
          setMaterials(response.data);
        })
        .catch((error) => console.error("Failed to fetch materials:", error));
    }
  };
  const fetchDependentMaterials = (categoryId, repairStyleId) => {
    if (categoryId && repairStyleId) {
      axios
        .get(
          `https://api.emotix.dev/api/v1/e-prorab/dependent-materials/filter?categoryId=${categoryId}&repairStyleId=${repairStyleId}`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        )
        .then((response) => {
          setDependentMaterials(response.data);
        })
        .catch((error) => console.error("Failed to fetch materials:", error));
    }
  };
  const fetchCategories = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/material-categories", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => console.error("Failed to fetch categories:", error));
  };

  const fetchRepairStyles = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repair-styles", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setRepairStyles(response.data);
      })
      .catch((error) => console.error("Failed to fetch repair styles:", error));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const numericFields = [
      "measureCapacity",
      "usagePerSquareMeter",
      "lowPrice",
      "midPrice",
      "highPrice",
      "servicePrice",
    ];

    setMaterial((prev) => ({
      ...prev,
      [name]: numericFields.includes(name) ? parseFloat(value) || 0 : value,
    }));
  };

  const handleNameDescriptionChange = (event) => {
    const { name, value, dataset } = event.target;
    setMaterial((prev) => ({
      ...prev,
      [dataset.field]: { ...prev[dataset.field], [name]: value },
    }));
  };

  const handleDropdownChange = (event, field) => {
    const { value } = event.target;
    setMaterial((prev) => ({
      ...prev,
      [field]: [{ id: parseInt(value) }],
    }));
  };
  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(
      "https://api.emotix.dev/api/v1/e-prorab/mono-files/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  };

  const handleImageUpload = (event, type) => {
    // Ensure the event target is the input element
    const fileInput = event.target;

    // Check if files are available
    if (fileInput && fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];

      // Proceed with the image upload
      uploadImage(file)
        .then((response) => {
          const imageId = response.data.id;
          // Update the store with the image ID
          setMaterial((prevMaterial) => {
            if (type === "image") {
              return {
                ...prevMaterial,
                image: { ...prevMaterial.image, id: imageId },
              };
            } else if (type === "imageOnRoom") {
              return {
                ...prevMaterial,
                imageOnRoom: {
                  ...prevMaterial.imageOnRoom,
                  id: imageId,
                },
              };
            }
            return prevMaterial;
          });
        })
        .catch((error) => {
          console.log("Failed to upload image:", error);
        });
    } else {
      console.log("No file selected");
    }
  };

  const handleDependentMaterialSelect = (materialId) => {
    if (
      !material.dependentMaterials.some(
        (dependentMaterial) => dependentMaterial.id === materialId
      )
    ) {
      setMaterial((prevDependentMaterial) => ({
        ...prevDependentMaterial,
        dependentMaterials: [{ id: materialId }],
      }));
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const url = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/materials/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/materials";

    const axiosMethod = isEditing ? axios.put : axios.post;

    axiosMethod(url, material, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setMaterial({
          name: { az: "", en: "", ru: "" },
          description: { az: "", en: "", ru: "" },
          categories: [{ id: null }],
          repairStyles: [{ id: null }],
          unitOfMeasure: "",
          measureCapacity: 0,
          usagePerSquareMeter: 0,
          lowPrice: 0,
          midPrice: 0,
          highPrice: 0,
          servicePrice: 0,
          currency: "AZN",
          image: { id: null },
          imageOnRoom: { id: null },
          isSelectable: true,
          dependentMaterials: [{ id: null }],
          status: true,
        });
        fetchMaterials(selectedCategoryId, selectedRepairStyleId);
      })
      .catch((error) => console.error(error));
  };

  const handleEdit = (material) => {
    setMaterial(material);
    setEditId(material.id);
    setIsEditing(true);
  };

  const handleCategoryChange = (event) => {
    const categoryId = parseInt(event.target.value);
    setSelectedCategoryId(categoryId);
    if (selectedRepairStyleId) {
      fetchMaterials(categoryId, selectedRepairStyleId);
    }
  };

  const handleRepairStyleChange = (event) => {
    const repairStyleId = parseInt(event.target.value);
    setSelectedRepairStyleId(repairStyleId);
    if (selectedCategoryId) {
      fetchMaterials(selectedCategoryId, repairStyleId);
    }
  };
  const handleDelete = (id) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/materials/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => fetchMaterials())
      .catch((error) => console.log(error));
  };
  const showData = () => {
    console.log("Material Data:", material);
    alert("Check the console for the material data.");
  };
  return (
    <div className="material-manager px-3">
      <h3 className="text-center text-primary">Material Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
        <h5 className="text-center fs-3 fw-bold">Material Details</h5>
        {["az", "en", "ru"].map((lang) => (
          <div key={lang}>
            <label>Name ({lang})</label>
            <input
              type="text"
              value={material.name[lang]}
              onChange={handleNameDescriptionChange}
              name={lang}
              data-field="name"
              required
            />

            <label>Description ({lang})</label>
            <input
              type="text"
              value={material.description[lang]}
              onChange={handleNameDescriptionChange}
              name={lang}
              data-field="description"
              required
            />
          </div>
        ))}
        <label className="text-center fw-bold fs-3">Category</label>
        <select
          value={material.categories[0]?.id || ""}
          onChange={(e) => handleDropdownChange(e, "categories")}
          required
        >
          <option value="" disabled>
            Select Category
          </option>
          {categories.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.name.text}
            </option>
          ))}
        </select>
        <label>Repair Style</label>
        <select
          value={material.repairStyles[0]?.id || ""}
          onChange={(e) => handleDropdownChange(e, "repairStyles")}
          required
        >
          <option value="" disabled>
            Select Repair Style
          </option>
          {repairStyles.map((style) => (
            <option key={style.id} value={style.id}>
              {style.name.text}
            </option>
          ))}
        </select>
        <h5 className="text-center fs-3 fw-bold"> Details</h5>
        <label>Unit of Measure</label>
        <input
          type="text"
          value={material.unitOfMeasure}
          onChange={handleInputChange}
          name="unitOfMeasure"
          required
        />
        <label>Measure Capacity</label>
        <input
          type="number"
          value={material.measureCapacity}
          onChange={handleInputChange}
          name="measureCapacity"
          required
        />
        <label>Usage Per Square Meter</label>
        <input
          type="number"
          value={material.usagePerSquareMeter}
          onChange={handleInputChange}
          name="usagePerSquareMeter"
          required
        />
        <label>Low Price</label>
        <input
          type="number"
          value={material.lowPrice}
          onChange={handleInputChange}
          name="lowPrice"
          required
        />
        <label>Mid Price</label>
        <input
          type="number"
          value={material.midPrice}
          onChange={handleInputChange}
          name="midPrice"
          required
        />
        <label>High Price</label>
        <input
          type="number"
          value={material.highPrice}
          onChange={handleInputChange}
          name="highPrice"
          required
        />
        <label>Service Price</label>
        <input
          type="number"
          value={material.servicePrice}
          onChange={handleInputChange}
          name="servicePrice"
          required
        />
        <label>Currency</label>
        <input
          type="text"
          value={material.currency}
          onChange={handleInputChange}
          name="currency"
          required
        />
        <h4 className="text-center fw-bold my-3">
          Dependent Material Selection
        </h4>
        <div className="d-flex align-items-center justify-content-around">
          <select
            onChange={(e) => {
              setCategoryId(e.target.value);
              fetchDependentMaterials(e.target.value, repairStyleId);
            }}
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name.text}
              </option>
            ))}
          </select>

          <select
            onChange={(e) => {
              setRepairStyleId(e.target.value);
              fetchDependentMaterials(categoryId, e.target.value);
            }}
          >
            <option value="">Select Repair Style</option>
            {repairStyles.map((repairStyle) => (
              <option key={repairStyle.id} value={repairStyle.id}>
                {repairStyle.name.text}
              </option>
            ))}
          </select>
        </div>
        <h5 className="text-center my-3">Available Materials</h5>
        <ol>
          {dependentMaterials.map((dependentMaterial) => (
            <li key={dependentMaterial.id} className="mb-3">
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={() =>
                  handleDependentMaterialSelect(dependentMaterial.id)
                }
              >
                {dependentMaterial.name.az}
              </button>
            </li>
          ))}
        </ol>
        <h5 className="text-center fs-3 fw-bold"> Images</h5>
        <label className="text-center fs-5 fw-bold my-2">Upload Image</label>
        <input
          type="file"
          onChange={(e) => handleImageUpload(e, "image")}
          accept="image/*"
        />

        <label className="text-center fs-5 fw-bold my-2">
          Upload Image on Room
        </label>
        <input
          type="file"
          onChange={(e) => handleImageUpload(e, "imageOnRoom")}
          accept="image/*"
        />
        <div className="d-flex align-items-center gap-5 my-3">
          <h5 className="m-0">Selectable</h5>
          <div className="selectable-checkbox">
            <input
              type="checkbox"
              checked={material.isSelectable}
              onChange={(e) =>
                setMaterial((prev) => ({
                  ...prev,
                  isSelectable: e.target.checked,
                }))
              }
            />
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
      <button className="btn btn-secondary mt-2" onClick={showData}>
        Show Data
      </button>
      <h3 className="text-center fw-bold mt-5 mb-3"> My Materials</h3>
      <select
        value={selectedCategoryId || ""}
        onChange={handleCategoryChange}
        required
      >
        <option value="" disabled>
          Select Category
        </option>
        {categories.map((cat) => (
          <option key={cat.id} value={cat.id}>
            {cat.name.text}
          </option>
        ))}
      </select>
      <select
        value={selectedRepairStyleId || ""}
        onChange={handleRepairStyleChange}
        required
      >
        <option value="" disabled>
          Select Repair Style
        </option>
        {repairStyles.map((style) => (
          <option key={style.id} value={style.id}>
            {style.name.text}
          </option>
        ))}
      </select>
      <div className="table-responsive mt-3">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="text-center">
                №
              </th>
              <th scope="col">Name (az)</th>
              <th scope="col">Name (en)</th>
              <th scope="col">Name (ru)</th>
              <th scope="col">Description (az)</th>
              <th scope="col">Description (en)</th>
              <th scope="col">Description (ru)</th>
              <th scope="col">Material Category</th>
              <th scope="col">Repair Style</th>
              <th scope="col">Unit of Measure</th>
              <th scope="col">Measure Capacity</th>
              <th scope="col">Usage Per Square Meter</th>
              <th scope="col">Low Price</th>
              <th scope="col">Mid Price</th>
              <th scope="col">High Price</th>
              <th scope="col">Service Price</th>
              <th scope="col">Currency</th>
              <th scope="col">Image</th>
              <th scope="col">Image on Room</th>
              <th scope="col" className="text-center">
                Edit
              </th>
              <th scope="col" className="text-center">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {materials.map((type, index) => {
              const base64String = `data:image/png;base64,${type.image.hashBase64}`;
              const base64String2 = `data:image/png;base64,${type.imageOnRoom.hashBase64}`;
              return (
                <>
                  <tr key={type.id}>
                    <th scope="row" className="text-center">
                      {index + 1}
                    </th>
                    <td>{type.name.az}</td>
                    <td>{type.name.en}</td>
                    <td>{type.name.ru}</td>
                    <td>{type.description.az}</td>
                    <td>{type.description.en}</td>
                    <td>{type.description.ru}</td>
                    <td>{type.categories[0].name.az}</td>
                    <td>{type.repairStyles[0].name.az}</td>
                    <td>{type.unitOfMeasure}</td>
                    <td>{type.measureCapacity}</td>
                    <td>{type.usagePerSquareMeter}</td>
                    <td>{type.lowPrice}</td>
                    <td>{type.midPrice}</td>
                    <td>{type.highPrice}</td>
                    <td>{type.servicePrice}</td>
                    <td>{type.currency}</td>
                    <td>
                      <img
                        src={base64String}
                        alt={type.name.az}
                        width={100}
                        height={100}
                        style={{ borderRadius: "12px" }}
                      />
                    </td>
                    <td>
                      <img
                        src={base64String2}
                        alt={type.name.az}
                        width={100}
                        height={100}
                        style={{ borderRadius: "12px" }}
                      />
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-outline-warning me-3"
                        onClick={() => handleEdit(type)}
                      >
                        Edit
                      </button>
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => handleDelete(type.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MaterialManager;
