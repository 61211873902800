import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles/scss/global.scss";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "./i18n/i18next";
import { RepairTariffProvider } from "./context/RepairTariff";
import { PropertyTypeProvider } from "./context/PropertyType";
import { RepairStyleProvider } from "./context/RepairStyle";
import { RoomTypeProvider } from "./context/RoomType";
import { SpaceProvider } from "./context/Space";
import { MaterialCategoryProvider } from "./context/MaterialCategory";
import { MaterialProvider } from "./context/MaterialContext";
import { PropertyStatusesProvider } from "./context/PropertyStatuses";
import { OrderProvider } from "./context/Order";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <OrderProvider>
      <MaterialProvider>
        <SpaceProvider>
          <MaterialCategoryProvider>
            <RoomTypeProvider>
              <PropertyStatusesProvider>
                <PropertyTypeProvider>
                  <RepairStyleProvider>
                    <RepairTariffProvider>
                      <App />
                    </RepairTariffProvider>
                  </RepairStyleProvider>
                </PropertyTypeProvider>
              </PropertyStatusesProvider>
            </RoomTypeProvider>
          </MaterialCategoryProvider>
        </SpaceProvider>
      </MaterialProvider>
    </OrderProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
