import React, { useContext } from "react";
import HeaderAccount from "../../../components/HeaderAccount";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OrderContext } from "../../../context/Order";

const MyHome = () => {
  const { t } = useTranslation();
  document.title = t("myHome.0");
  const headerTitle = t("myHome.0");
  const backwardNavigate = "/account";
  const [order] = useContext(OrderContext);
  const navigate = useNavigate();
  const properties = order.map((o) => o.property).filter((p) => !!p);
  const rooms = properties.flatMap((property) => property.rooms || []);
  const groupedRooms = rooms.reduce((acc, room) => {
    const spaceName = room.roomType.space.name.text;
    if (!acc[spaceName]) {
      acc[spaceName] = [];
    }
    acc[spaceName].push(room);
    return acc;
  }, {});

  const handlePropertyClick = (room) => {
    const property = properties.find((p) => p.rooms?.includes(room));
    if (property) {
      navigate(`/account/myhome/${property.id}`);
    }
  };

  return (
    <div className="myhome-section px-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />

      <div>
        {Object.entries(groupedRooms).map(([spaceName, roomList], index) => (
          <div key={index} className="myhome-section">
            <h4 className="mt-3">{spaceName}</h4>
            <div className="room-list">
              {roomList.map((room, roomIndex) => (
                <div
                  className="section d-flex align-items-center"
                  onClick={() => handlePropertyClick(room)}
                  style={{ cursor: "pointer" }}
                  key={roomIndex}
                >
                  <div className="left-section">
                    <i className={`${room.roomType.iconType}`}></i>
                  </div>
                  <div className="right-section d-flex align-items-center justify-content-between w-100">
                    <div className="property-title ms-3">
                      <h2>{room.roomType.name.text}</h2>
                    </div>
                    <div className="detail-section me-2">
                      <i className="fa-solid fa-chevron-right"></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyHome;
