import React, { useState, useEffect } from "react";
import RoomDropdown from "../../components/RoomDropdown/RoomDropdown";
import { useNavigate } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Header from "../../components/Header";
import { ToastContainer, toast } from "react-toastify";
import SelectedDate from "../../assets/icons/SelectedDate.svg";
import SelectedTime from "../../assets/icons/SelectedTime.svg";
import RoomAdd from "../../assets/icons/RoomAdd.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useTranslation } from "react-i18next";
const DimensionRoom = () => {
  const { t } = useTranslation();
  const theme = createTheme({
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {},
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            fontSize: "0.5rem",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            width: "44px",
            height: "44px",
            fontSize: "1rem",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            padding: "22px",
            width: "44px",
            height: "44px",
            fontSize: "1rem",
          },
        },
      },
    },
  });
  document.title = t("dimensionRoom.0");
  const headerTitle = t("dimensionRoom.0");
  const navigate = useNavigate();
  const loadInitialRoomValues = () => {
    const savedValues = sessionStorage.getItem("roomValues");
    return savedValues
      ? JSON.parse(savedValues).map((room) => ({
          ...room,
          area: parseFloat(room.area),
          height: parseFloat(room.height),
          width: parseFloat(room.width),
          length: parseFloat(room.length),
        }))
      : [];
  };
  const [content, setContent] = useState(1);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const [roomValues, setRoomValues] = useState(loadInitialRoomValues);
  const [value, setValue] = useState(null);
  const [time, setTime] = useState(null);
  const [type, setType] = useState({
    width: "",
    length: "",
    height: "",
    area: "",
  });
  const handleDateChange = (newValue) => {
    setValue(newValue);
  };

  const handleTimeChange = (newTime) => {
    setTime(newTime);
    if (value) {
      const selectedDateTime =
        value.format("YYYY-MM-DD") + "T" + newTime.format("HH:mm:ss");
      sessionStorage.setItem("selectedDateTime", selectedDateTime);
    }
  };
  const handleRoomChange = (newRoom, iconType) => {
    setSelectedRoom(newRoom);
    if (!roomValues.some((room) => room.id === newRoom.id)) {
      setRoomValues((prevValues) => [
        ...prevValues,
        { id: newRoom.id, name: { text: newRoom.name.text }, iconType },
      ]);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedSelectedRoom = {
      ...selectedRoom,
      [name]: value,
    };
    if (name === "width" || name === "length") {
      const width = name === "width" ? value : updatedSelectedRoom.width || 0;
      const length =
        name === "length" ? value : updatedSelectedRoom.length || 0;
      updatedSelectedRoom.area = parseFloat(width) * parseFloat(length) || "";
    }

    // Sadece selectedRoom'ı güncelle
    setSelectedRoom(updatedSelectedRoom);
  };

  const [openRoom, setOpenRoom] = useState(null);
  const handleToggle = (roomId) => {
    setOpenRoom((prevRoom) => (prevRoom === roomId ? null : roomId));

    const selected = roomValues.find((room) => room.id === roomId);
    setSelectedRoom(selected || null);
  };
  const hasRoomBeenSaved = (roomId) => {
    return roomValues.some((room) => room.id === roomId);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const currentRoomValues = roomValues.find(
      (room) => room.id === selectedRoom?.id
    );
    if (
      !currentRoomValues?.height ||
      !currentRoomValues?.width ||
      !currentRoomValues?.length
    ) {
      toast.error("Zəhmət olmasa ölçüləri daxin edin", {
        position: "top-right",
      });
      return;
    }

    // Burada roomValues'ı güncelle
    setRoomValues((prevValues) =>
      prevValues.map((room) =>
        room.id === selectedRoom.id ? { ...room, ...selectedRoom } : room
      )
    );

    // selectedRoom'ı yönlendirin
    toast.success("Dəyişikliklər uğurla yadda saxlandı!");
  };
  const handleModalSubmit = (e) => {
    e.preventDefault();

    if (!selectedRoom || !selectedRoom.name) {
      toast.error("Lütfen oda adını girin!");
      return;
    }

    const newRoom = {
      width: selectedRoom.width,
      length: selectedRoom.length,
      height: selectedRoom.height,
      area: parseFloat(selectedRoom.width) * parseFloat(selectedRoom.length),
      name: selectedRoom.name.text,
    };

    setRoomValues((prevValues) => [
      ...prevValues,
      { id: prevValues.length + 1, ...newRoom },
    ]);

    setSelectedRoom({
      width: "",
      length: "",
      height: "",
      area: "",
      name: { text: "" },
    });

    toast.success("Oda başarıyla eklendi!");
  };
  const completedRooms = roomValues.filter(
    (room) => room.height != null && room.width != null && room.length != null
  );
  useEffect(() => {
    const roomsWithValues = completedRooms
      .filter((room) => room.area && room.height && room.width && room.length)
      .map((room) => ({
        ...room,
        area: parseFloat(room.area) || 0,
        height: parseFloat(room.height) || 0,
        width: parseFloat(room.width) || 0,
        length: parseFloat(room.length) || 0,
      }));

    if (roomsWithValues.length > 0) {
      sessionStorage.setItem("roomValues", JSON.stringify(roomsWithValues));
    } else {
      sessionStorage.setItem("roomValues", JSON.stringify([]));
    }
  }, [completedRooms]);
  const [isHandleForward, setIsHandleForward] = useState(false);
  const handleForward = () => {
    setIsHandleForward(true);
    const errors = {};
    let hasError = false;

    roomValues.forEach((room) => {
      if (!room.width || !room.length || !room.height || !room.area) {
        hasError = true;
        errors[room.id] = true;
      } else {
        navigate("/material");
      }
    });

    console.log("İşlem başarılı, devam ediliyor.");
  };
  const inputStyle = (name) => {
    if (isHandleForward && !type[name]) {
      return { border: "1px solid red" };
    }
    return {};
  };
  const handleRoomDelete = (roomId) => {
    setRoomValues((roomValues) =>
      roomValues.filter((room) => room.id !== roomId)
    );
    // Aynı zamanda sessionStorage'dan da temizlemeyi unutma
    sessionStorage.setItem(
      "roomValues",
      JSON.stringify(roomValues.filter((room) => room.id !== roomId))
    );
  };
  useEffect(() => {
    sessionStorage.setItem("roomValues", JSON.stringify(roomValues));
  }, [roomValues]);

  const sendRepairStyleID = JSON.parse(
    sessionStorage.getItem("selectedRepairStyle")
  )?.id;
  const selectedPropertyType = JSON.parse(
    sessionStorage.getItem("selectedPropertyType")
  );
  const sendPropertyStatusID = JSON.parse(
    sessionStorage.getItem("selectedPropertyStatuses")
  )?.id;
  const selectedHomeData = JSON.parse(sessionStorage.getItem("homeData"));
  const sendPropertyTypeID = JSON.parse(
    sessionStorage.getItem("selectedPropertyType")
  )?.id;
  const sendRepairTariffID = JSON.parse(
    sessionStorage.getItem("selectedRepairTariff")
  )?.id;
  const sendHomeData = JSON.parse(sessionStorage.getItem("homeData"));
  const selectedDateTime = sessionStorage.getItem("selectedDateTime");
  const data = {
    repairStyle: {
      id: sendRepairStyleID,
    },
    repairTariff: {
      id: sendRepairTariffID,
    },
    repairmanDate: selectedDateTime,
    property: {
      propertyType: {
        id: sendPropertyTypeID,
      },
      propertyStatus: {
        id: sendPropertyStatusID,
      },
      area: sendHomeData?.areaHouse,
      height: sendHomeData?.heightWall,
      roomCount: sendHomeData?.roomCount,
      doorCount: sendHomeData?.roomDoorCount,
      status: true,
    },
    status: true,
  };
  console.log(completedRooms);
  const handleSubmitForeman = (e) => {
    if (selectedDateTime) {
      let data2 = JSON.stringify(data);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.emotix.dev/api/v1/e-prorab/repairman-dates",
        headers: {
          "Content-Type": "application/json",
        },
        data: data2,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          console.log(response.data);
          const repairId = response.data.id;
          localStorage.setItem("repairId", repairId);
          navigate("/signup");
        })
        .catch((error) => {
          console.log(error);
        });
      e.preventDefault();
    }
  };

  const renderContent = () => {
    if (content === 1) {
      return (
        <>
          <div className="room-selection-section px-2">
            <div className="home-data d-flex align-items-center justify-content-between gap-3 mb-5">
              <div className="left-section d-flex align-items-center gap-2">
                <i className={`${selectedPropertyType?.iconType}`}></i>
                <h4 className="m-0"> {selectedPropertyType?.name.text}</h4>
              </div>{" "}
              <div className="right-section align-items-center">
                <h4 htmlFor="area" className="m-0">
                  {t("calculator.2")} :{" "}
                  <span className="fs-6">
                    {selectedHomeData?.areaHouse || ""} m²
                  </span>
                </h4>
                <h4 className="m-0">
                  {t("calculator.3")} :{" "}
                  <span className="fs-6">
                    {selectedHomeData?.heightWall || ""} m
                  </span>
                </h4>
              </div>
            </div>

            <button
              className="room-addition btn d-flex align-items-center justify-content-evenly gap-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              type="button"
            >
              <div className="left-section">
                <img src={RoomAdd} alt="" />
              </div>
              <div className="right-section">
                <h3>{t("dimensionRoom.12")}</h3>
                <p>{t("dimensionRoom.13")}</p>
              </div>
            </button>
            <div className="room-section">
              <h5>{t("dimensionRoom.14")}</h5>
              <div className="row g-2">
                {completedRooms.length > 0 ? (
                  completedRooms.map((type, index) => (
                    <div className="col-12" key={index}>
                      <div
                        className="d-flex align-items-center justify-content-between room-title"
                        onClick={() => handleToggle(type.id)}
                      >
                        <div className="left-section">
                          <h3>{type.name}</h3>
                        </div>

                        <div className="right-section">
                          <span
                            style={{
                              fontWeight: "400",
                              marginRight: "10px",
                              fontSize: "16px",
                              color: "#6A6A6A",
                            }}
                          >
                            düzəliş
                          </span>
                          <i
                            className={
                              openRoom === type.id
                                ? "fa-solid fa-pen-to-square"
                                : "fa-regular fa-pen-to-square"
                            }
                          ></i>
                        </div>
                      </div>
                      {openRoom === type.id && (
                        <div className="room">
                          <div className="row g-3">
                            {["width", "length", "height"].map((field, idx) => (
                              <div className="col-6" key={idx}>
                                <form>
                                  <label>
                                    {t(`dimensionRoom.${idx + 1}`)} (m):
                                    <input
                                      type="text"
                                      name={field}
                                      value={selectedRoom?.[field] || ""}
                                      style={inputStyle(field)}
                                      onChange={(e) =>
                                        handleInputChange(e, type.id)
                                      }
                                    />
                                  </label>
                                </form>
                              </div>
                            ))}
                            <div className="col-6">
                              <form>
                                <label>
                                  {t("dimensionRoom.4")} (m²):
                                  <input
                                    type="text"
                                    name="area"
                                    value={selectedRoom?.area || ""}
                                    readOnly
                                  />
                                </label>
                              </form>
                            </div>
                          </div>
                          <div className="d-flex gap-3 mt-4">
                            <button
                              className="global-button py-3"
                              onClick={() => handleRoomDelete(type.id)}
                            >
                              Otağı Sil
                            </button>
                            <button
                              className="global-button py-3"
                              onClick={handleSubmit}
                            >
                              Yadda Saxla
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <h5 className="text-center">{t("dimensionRoom.15")}</h5>
                )}
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <RoomDropdown onChange={handleRoomChange} />
                </div>
                <div className="modal-body">
                  {selectedRoom && (
                    <div className="bottom-section d-flex align-items-center justify-content-between">
                      <form>
                        <div className="row mb-3">
                          <div className="col-6">
                            <div className="width-of-house d-flex flex-column">
                              <label className="mb-3">
                                {t("dimensionRoom.1")} (m):
                              </label>
                              <input
                                type="number"
                                name="width"
                                placeholder="m"
                                value={selectedRoom?.width || ""}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="length-of-room d-flex flex-column">
                              <label className="mb-3">
                                {t("dimensionRoom.2")} (m):
                              </label>
                              <input
                                type="number"
                                name="length"
                                placeholder="m"
                                value={selectedRoom?.length || ""}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-6 mt-3">
                            <div className="height-of-wall d-flex flex-column">
                              <label className="mb-3">
                                {t("dimensionRoom.3")} (m):
                              </label>
                              <input
                                required
                                type="number"
                                name="height"
                                placeholder="m"
                                value={selectedRoom?.height || ""}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-6 mt-3">
                            <div className="area-of-house d-flex flex-column">
                              <label className="mb-3">
                                {t("dimensionRoom.4")} (m²):
                              </label>
                              <input
                                type="number"
                                name="area"
                                placeholder="m²"
                                value={selectedRoom?.area || ""}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    className="btn w-100"
                    data-bs-dismiss="modal"
                    onClick={handleModalSubmit}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="button-section">
            <button className="btn py-3 global-button" onClick={handleForward}>
              {t("dimensionRoom.5")}
            </button>
          </div>
          <ToastContainer />
        </>
      );
    } else if (content === 2) {
      return (
        <div className="calendar-section">
          <h5 className="mb-3">{t("dimensionRoom.6")}</h5>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
              <DemoContainer components={["DateCalendar", "DateCalendar"]}>
                <div className="d-flex align-items-center justify-content-between bg-white px-3 mt-2 time-selection">
                  <DateCalendar
                    disablePast
                    value={value}
                    onChange={handleDateChange}
                  />
                </div>
              </DemoContainer>
            </ThemeProvider>
            {value && (
              <div className="d-flex align-items-center justify-content-between bg-white px-3 mt-2 time-selection">
                <div className="left-side">{t("dimensionRoom.7")}</div>
                <div className="right-side">
                  <TimePicker
                    value={time}
                    onChange={handleTimeChange}
                    ampm={false}
                    ampmInClock={false}
                  />
                </div>
              </div>
            )}
          </LocalizationProvider>

          <div className="chosen-calendar mt-3 px-1">
            <div className="chosen-text mb-2">
              <h6>{t("dimensionRoom.8")}:</h6>
            </div>
            <div className="d-flex gap-4">
              <div className="left-side">
                <div className="chosen-date d-flex align-items-center gap-1">
                  <img src={SelectedDate} alt="calendar" width={22} />
                  <h6>{value?.format("DD MMMM") || "-"}</h6>
                </div>
              </div>
              <div className="right-side">
                <div className="chosen-time d-flex align-items-center gap-1">
                  <img
                    src={SelectedTime}
                    alt="clock"
                    width={22}
                    className="mt-1"
                  />
                  <h6>{time?.format("HH:mm") || "-"}</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="button-section">
            <button
              className="btn py-3 global-button"
              onClick={handleSubmitForeman}
            >
              {t("dimensionRoom.9")}
            </button>
          </div>
          <ToastContainer />
        </div>
      );
    }
  };

  return (
    <div className="dimesion-room-section mx-3">
      <Header title={headerTitle} showProfile={true} />
      <div className="top-section mt-3 d-flex align-items justify-content-around">
        <div className="section section-1">
          <button
            className="btn px-5"
            onClick={() => setContent(1)}
            style={{
              backgroundColor: content === 1 ? "#7B7D51" : "transparent",
              color: content === 1 ? "#ffffff" : "#7B7D51",
            }}
          >
            {t("dimensionRoom.10")}
          </button>
        </div>
        <div className="section section-2">
          <button
            className="btn px-5"
            onClick={() => setContent(2)}
            style={{
              backgroundColor: content === 2 ? "#7B7D51" : "transparent",
              color: content === 2 ? "#ffffff" : "#7B7D51",
            }}
          >
            {t("dimensionRoom.11")}
          </button>
        </div>
      </div>
      {renderContent()}
    </div>
  );
};

export default DimensionRoom;
