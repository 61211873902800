import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioDone from "../assets/icons/RadioDone.svg";
import { RepairTariffContext } from "../context/RepairTariff";
import { Collapse } from "@mui/material";
const AccordionItem = ({
  title,
  description,
  minValue,
  maxValue,
  index,
  expandedIndex,
  onToggle,
  isFirst,
  isLast,
  item,
}) => {
  const isActive = expandedIndex === index;
  return (
    <div
      style={{
        overflow: "hidden",
        ...(isFirst && {
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }),
        ...(isLast && {
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }),
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          cursor: "pointer",
          backgroundColor: isActive ? "#f0f0f0" : "#fff",
          ...(isFirst && {
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }),
          ...(isLast &&
            isActive && {
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
            }),
        }}
        onClick={() => onToggle(index, item)}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {isActive ? (
            <img
              src={RadioDone}
              alt="Radio Done"
              style={{ marginRight: "10px", width: "20px", height: "20px" }}
            />
          ) : (
            <input
              type="radio"
              checked={false}
              readOnly
              style={{
                marginRight: "10px",
                width: "14px",
                height: "14px",
                accentColor: "currentColor",
                transform: "scale(1.2)",
              }}
            />
          )}
          <span
            style={{
              fontWeight: 500,
              fontSize: 14,
            }}
          >
            {title} ({minValue}-{maxValue} AZN)
          </span>
        </div>
        <ExpandMoreIcon
          style={{
            transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </div>

      <Collapse in={isActive}>
        <div
          style={{
            backgroundColor: "#f9f9f9",
            padding: "10px",
            ...(isLast && {
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
            }),
          }}
        >
          <p
            style={{
              fontWeight: 400,
              fontSize: "10px",
              lineHeight: "1.4",
              marginTop: "5px",
              color: "#111827",
            }}
          >
            {description}
          </p>
        </div>
      </Collapse>
    </div>
  );
};

const Accordion = () => {
  const [repairTariff, setSelectedRepairTariff, selectedRepairTariff] =
    useContext(RepairTariffContext);

  const repairTariffs = Array.isArray(repairTariff) ? repairTariff : [];
  const [expandedIndex, setExpandedIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedRepairTariff) {
      const index = repairTariffs.findIndex(
        (item) => item.id === selectedRepairTariff.id
      );
      setExpandedIndex(index);
    }
  }, [repairTariffs, selectedRepairTariff]);

  const handleToggle = (index, item) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
      setSelectedRepairTariff(null);
      sessionStorage.removeItem("selectedRepairTariff");
    } else {
      setExpandedIndex(index);
      setSelectedRepairTariff(item);
    }
  };
  return (
    <div
      style={{
        borderRadius: "8px",
        overflow: "hidden",
        border: "1px solid #ddd",
      }}
    >
      {repairTariffs.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.name.text}
          description={item.description.text}
          minValue={item.minValue}
          maxValue={item.maxValue}
          index={index}
          expandedIndex={expandedIndex}
          onToggle={handleToggle}
          isFirst={index === 0}
          isLast={index === repairTariffs.length - 1}
          item={item}
        />
      ))}
    </div>
  );
};

export default Accordion;
