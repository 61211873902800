import React, { useEffect } from "react";
import SuccessLoginImg from "../../assets/images/SuccessLogin.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const SuccessLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const repairId = JSON.parse(localStorage.getItem("repairId"));
  const userId = JSON.parse(localStorage.getItem("user")).id;
  const token = localStorage.getItem("token");

  const order = {
    user: {
      id: userId,
    },
    repair: {
      id: repairId,
    },
    orderStatus: "PENDING",
    status: true,
  };

  const handleSubmit = () => {
    axios
      .post("https://api.emotix.dev/api/v1/e-prorab/orders", order, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    navigate(`/allmaterials/${repairId}`);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSubmit();
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="succcesslogin-section">
      <img src={SuccessLoginImg} alt="success" onClick={handleSubmit} />
      <h4 className="text-center">{t("successLogin.0")}</h4>
      <p className="text-center">{t("successLogin.1")}</p>
    </div>
  );
};

export default SuccessLogin;
