import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { SquareLoader } from "react-spinners";

const StatusController = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedDateTime = sessionStorage.getItem("selectedDateTime");
  const selectedMaterial = sessionStorage.getItem("selectedMaterial");

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (code) {
      axios
        .get(
          `https://api.emotix.dev/api/v1/e-prorab/auth/login/oauth2/code/google?code=${code}`
        )
        .then((response) => {
          const { data } = response;
          localStorage.setItem("token", data.token);
          localStorage.setItem("user", JSON.stringify(data.user));
          if (!selectedDateTime && !selectedMaterial) {
            navigate("/home");
          } else if (selectedMaterial) {
            setTimeout(() => {
              navigate("/successlogin");
            }, 2000);
          } else {
            alert("Təşəkkürlər, Sizinlə qısa müddətdə əlaqə saxlanılacaq");
            navigate("/home");
          }
        })
        .catch((error) => {
          console.error("Error during Google login:", error);
        });
    }
  }, [location, navigate]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <SquareLoader
        color="#8c920b"
        cssOverride={{
          position: "absolute",
          top: "45%",
          bottom: "45%",
        }}
        size={100}
        speedMultiplier={1}
      />
    </div>
  );
};

export default StatusController;
