import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const OrderContext = createContext(null);

export const OrderProvider = ({ children }) => {
  const [order, setOrder] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (!storedUser) {
      console.warn("User not found in localStorage");
      return;
    }

    let userId;
    try {
      const parsedUser = JSON.parse(storedUser);
      userId = parsedUser.id;
    } catch (error) {
      console.error("Error parsing user data from localStorage:", error);
      return;
    }

    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/orders", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const filteredOrders = response.data.filter(
          (order) => order.user.id === userId
        );
        if (filteredOrders.length > 0) {
          const repairs = filteredOrders.map((order) => order.repair);
          setOrder(repairs);
        } else {
          console.warn("No matching user found in orders");
        }
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, [token]);

  return (
    <OrderContext.Provider value={[order]}>{children}</OrderContext.Provider>
  );
};
