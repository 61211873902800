import React from "react";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const manager = [
    {
      id: 1,
      name: "Property Type Manager",
      navigate: "propertytypemanager",
    },
    {
      id: 2,
      name: "Repair Style Manager",
      navigate: "repairstylemanager",
    },
    {
      id: 3,
      name: "Repair Tariff Manager",
      navigate: "repairtariffmanager",
    },
    {
      id: 4,
      name: "Technical Area Manager",
      navigate: "technicalareamanager",
    },
    {
      id: 5,
      name: "Space Manager",
      navigate: "spacemanager",
    },
    {
      id: 6,
      name: "Room Type Manager",
      navigate: "roomtypemanager",
    },
    {
      id: 7,
      name: "Material Manager",
      navigate: "materialmanager",
    },
    {
      id: 8,
      name: "Material Category Manager",
      navigate: "materialcategorymanager",
    },
    {
      id: 9,
      name: "Store Manager",
      navigate: "storemanager",
    },
    {
      id: 10,
      name: "Dependent Material Manager",
      navigate: "dependentmaterialmanager",
    },
    {
      id: 11,
      name: "Repair Man Manager",
      navigate: "repairmanmanager",
    },
    {
      id: 12,
      name: "Property Status Manager",
      navigate: "propertystatusmanager",
    },
    {
      id: 13,
      name: "Repair Team Manager",
      navigate: "repairteammanager",
    },
  ];
  return (
    <div className="px-3">
      <h1 className="text-center my-3">Admin Dashboard</h1>
      <div className="row g-3">
        {manager.map((type) => (
          <div className="col-12" key={type.id}>
            <button
              className="btn btn-outline-dark p-3 fs-3 w-100"
              onClick={() => navigate(`/${type.navigate}`)}
            >
              {type.name}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
