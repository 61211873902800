import React, { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import RoomParametr from "../../../../assets/icons/RoomParametr.svg";
import RequiredMaterial from "../../../../assets/icons/RequiredMaterial.svg";
import Statistics from "../../../../assets/icons/Statistics.svg";
import DesignStyle from "../../../../assets/icons/DesignStyle.svg";
import RepairDate from "../../../../assets/icons/RepairDate.svg";
import RepairStatus from "../../../../assets/icons/RepairStatus.svg";
import MyHome from "../../../../assets/icons/MyHome.svg";
import RoomType from "../../../../assets/images/RoomTypeDetails.png";
import HeaderAccount from "../../../../components/HeaderAccount";
import { useTranslation } from "react-i18next";
import { OrderContext } from "../../../../context/Order";
const MyHomeDetails = () => {
  const { t } = useTranslation();
  document.title = t("myHomeDetails.0");
  const navigate = useNavigate();
  const [order] = useContext(OrderContext);
  const { id } = useParams();
  const backwardNavigate = "/account/myhome";
  const property =
    order.find((o) => String(o.property.id) === String(id))?.property || {};
  console.log("Matched Property:", property);

  const handlePropertyClick = (path) => {
    navigate(`/account/homeplan/${path}`);
  };
  return (
    <div className="my-home-details-section px-3">
      <HeaderAccount backwardNavigate={backwardNavigate} />
      <>
        {" "}
        <div className="top-section mb-5">
          <img src={RoomType} alt="calculator" />
          <div className="text-section text-center">
            {/* <h4>{roomType.charAt(0).toUpperCase() + roomType.slice(1)}</h4> */}
          </div>
        </div>
        <h4>{t("myHomeDetails.1")}</h4>
        <div className="middle-section">
          <div
            className="text-decoration-none"
            onClick={() => handlePropertyClick(property.id)}
          >
            <div className="section section-1 d-flex align-items-center">
              <div className="left-section">
                <img src={RoomParametr} alt="contact" />
              </div>
              <div className="right-section d-flex align-items-center justify-content-between w-100">
                <h2 className="mt-2">{t("myHomeDetails.2")}</h2>
                <div className="detail-section me-2">
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
          <Link
            to={`/allmaterials/${property.id}`}
            className="text-decoration-none"
          >
            <div className="section section-2 d-flex align-items-center">
              <div className="left-section">
                <img src={RequiredMaterial} alt="contact" />
              </div>
              <div className="right-section d-flex align-items-center justify-content-between w-100">
                <h2 className="mt-2">{t("myHomeDetails.3")}</h2>
                <div className="detail-section me-2">
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/account/contact" className="text-decoration-none">
            <div className="section section-1 d-flex align-items-center">
              <div className="left-section">
                <img src={Statistics} alt="contact" />
              </div>
              <div className="right-section d-flex align-items-center justify-content-between w-100">
                <h2 className="mt-2">{t("myHomeDetails.4")}</h2>
                <div className="detail-section me-2">
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <h4>{t("myHomeDetails.5")}</h4>
        <div className="bottom-section">
          <Link to="/account/myhome" className="text-decoration-none">
            <div className="section section-1 d-flex align-items-center">
              <div className="left-section">
                <img src={DesignStyle} alt="contact" />
              </div>
              <div className="right-section d-flex align-items-center justify-content-between w-100">
                <h2 className="mt-2">{t("myHomeDetails.6")}</h2>
                <div className="detail-section me-2">
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </Link>
          <Link
            to={`/maintenance/${property.id}`}
            className="text-decoration-none"
          >
            <div className="section section-2 d-flex align-items-center">
              <div className="left-section">
                <img src={RepairDate} alt="contact" />
              </div>
              <div className="right-section d-flex align-items-center justify-content-between w-100">
                <h2 className="mt-2">{t("myHomeDetails.7")}</h2>
                <div className="detail-section me-2">
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/account/contact" className="text-decoration-none">
            <div className="section section-1 d-flex align-items-center">
              <div className="left-section">
                <img src={MyHome} alt="contact" />
              </div>
              <div className="right-section d-flex align-items-center justify-content-between w-100">
                <h2 className="mt-2">{t("myHomeDetails.8")}</h2>
                <div className="detail-section me-2">
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/account/contact" className="text-decoration-none">
            <div className="section section-1 d-flex align-items-center">
              <div className="left-section">
                <img src={RepairStatus} alt="contact" />
              </div>
              <div className="right-section d-flex align-items-center justify-content-between w-100">
                <h2 className="mt-2">{t("myHomeDetails.9")}</h2>
                <div className="detail-section me-2">
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </>
    </div>
  );
};

export default MyHomeDetails;
