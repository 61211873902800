import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import Header from "../../components/Header";

const SharedWithFriend = () => {
  document.title = "Təmirin dəyəri və müddəti";
  // const headerTitle = "Təmir";
  const [data, setData] = useState({});
  const [duration, setDuration] = useState(null);
  const [materialsCost, setMaterialsCost] = useState(null);
  const [serviceFee, setServiceFee] = useState(null);
  const [generalCost, setGeneralCost] = useState(null);
  const { id } = useParams();
  console.log(id);
  useEffect(() => {
    axios
      .get(`https://api.emotix.dev/api/v1/e-prorab/repairs/shared-repair/${id}`)
      .then((response) => {
        const duration = response.data.duration || null;
        setDuration(duration);
        const materialsCost = response.data.materialsCost || null;
        setMaterialsCost(materialsCost);
        const serviceFee = response.data.serviceFee || null;
        setServiceFee(serviceFee);
        const generalCost = response.data.generalCost || null;
        setGeneralCost(generalCost);
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  return (
    <div className="share-with-friend-section mx-3">
      <>
        {/* <Header title={headerTitle} showProfile={true} /> */}
        <div className="top-section d-flex align-items-center justify-content-center mt-2">
          <h5 className="my-auto">Təmirin dəyəri və müddəti</h5>
        </div>
        <div className="middle-section mx-3">
          <div className="row gap-3">
            <div className="col-12 first-raw">
              <div className="row">
                <div className="col-6 first">Müddət</div>
                <div className="col-6 second">{duration ?? 60} gün</div>
              </div>
            </div>
            <div className="col-12 second-raw">
              <div className="row">
                <div className="col-6 first">Materiallar</div>
                <div className="col-6 second">
                  {Math.ceil(materialsCost)} {data.currency}
                </div>
              </div>
            </div>
            <div className="col-12 third-raw">
              <div className="row">
                <div className="col-6 first">Xidmət Haqqı</div>
                <div className="col-6 second">
                  {Math.ceil(serviceFee)} {data.currency}
                </div>
              </div>
            </div>
            <div className="col-12 fourth-raw">
              <div className="row">
                <div className="col-6 first">Cəmi</div>
                <div className="col-6 second">
                  {Math.ceil(generalCost)} {data.currency}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default SharedWithFriend;
