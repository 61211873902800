import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import HeaderAccount from "../../../components/HeaderAccount";
import { useTranslation } from "react-i18next";
import { OrderContext } from "../../../context/Order";
const HomePlan = () => {
  const { t } = useTranslation();
  document.title = t("homePlan.0");
  const headerTitle = t("homePlan.0");
  const backwardNavigate = "/account";
  const [order] = useContext(OrderContext);
  const navigate = useNavigate();
  const handlePropertyClick = (path) => {
    navigate(`/account/homeplan/${path}`);
  };
  return (
    <div className="account-home-plan-section mx-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />
      <div className="middle-section ">
        <div className="row g-1">
          {order.map((type, index) => (
            <div className="col-6" key={index}>
              <div
                className="box d-flex align-items-center justify-content-center flex-column"
                onClick={() => handlePropertyClick(type.property.id)}
                style={{ cursor: "pointer" }}
              >
                <div className="property-icon">
                  <i className={`${type.property.propertyType.iconType}`}></i>
                </div>
                <div className="property-title text-center">
                  <h5>{type.property.propertyType.name.text}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePlan;
