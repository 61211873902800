import React, { useContext } from "react";
import HeaderAccount from "../../../components/HeaderAccount";
import { useTranslation } from "react-i18next";
import { OrderContext } from "../../../context/Order";
const RepairHistory = () => {
  const { t } = useTranslation();
  document.title = t("repairHistory.0");
  const headerTitle = t("repairHistory.0");
  const backwardNavigate = "/account";
  const [order] = useContext(OrderContext);
  console.log(order);

  return (
    <div className="repair-history-section mx-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />
      {order.map((type, index) => (
        <>
          <div
            className="top-section d-flex align-items-center gap-2"
            key={index}
          >
            <span>{index + 1}.</span>
            <>
              <i className={`${type.property.propertyType.iconType}`}></i>
              <h4>{type.property.propertyType.name.text}</h4>
            </>
          </div>
          <div className="bottom-section">
            <div className="row g-3">
              {type.property.rooms.map((room) => (
                <div className="col-12 room-section" key={room.id}>
                  <div className="d-flex align-items-center">
                    <div className="left">
                      <i className={`${room.roomType.iconType} mx-3`}></i>
                    </div>
                    <div className="right">
                      <h6>{room.roomType.name.text}</h6>
                      <p>2025 - 2025</p>
                    </div>
                  </div>
                  <hr className="horizontal-line" />
                </div>
              ))}
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default RepairHistory;
