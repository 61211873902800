import axios from "axios";
import React, { useEffect, useState } from "react";
const PropertyStatusManager = () => {
  const [propertyStatusAdmin, setPropertyStatusAdmin] = useState([]);
  const [propertyStatus, setPropertyStatus] = useState({
    name: { az: "", en: "", ru: "" },
    iconType: "",
    status: true,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const authToken = localStorage.getItem("authToken");
  useEffect(() => {
    fetchPropertyStatuses();
  }, []);

  const fetchPropertyStatuses = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/property-statuses/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => setPropertyStatusAdmin(response.data))
      .catch((error) => console.log(error));
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    if (name === "az" || name === "en" || name === "ru") {
      setPropertyStatus((prevType) => ({
        ...prevType,
        name: { ...prevType.name, [name]: value },
      }));
    } else {
      setPropertyStatus((prevType) => ({ ...prevType, [name]: value }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const apiUrl = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/property-statuses/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/property-statuses";

    const axiosMethod = isEditing ? axios.put : axios.post;

    axiosMethod(apiUrl, propertyStatus, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setPropertyStatus({
          name: { az: "", en: "", ru: "" },
          iconType: "",
          status: true,
        });
        fetchPropertyStatuses();
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = (type) => {
    console.log("Editing Property Status:", type);
    setPropertyStatus(type);
    setEditId(type.id);
    setIsEditing(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(
        `https://api.emotix.dev/api/v1/e-prorab/property-statuses/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then(() => fetchPropertyStatuses())
      .catch((error) => console.log(error));
  };
  const showData = () => {
    console.log("Material Data:", propertyStatus);
    alert("Check the console for the material data.");
  };
  return (
    <div className="property-status-manager px-3">
      <h3 className="text-center text-primary">Property Status Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
        <div className="property-type-name">
          <h5 className="text-center">Property Name</h5>
          <label htmlFor="az">Azerbaijani</label>
          <input
            type="text"
            value={propertyStatus.name.az}
            onChange={handleInput}
            name="az"
            required
          />
          <label htmlFor="en">English</label>
          <input
            type="text"
            value={propertyStatus.name.en}
            onChange={handleInput}
            name="en"
            required
          />
          <label htmlFor="ru">Russian</label>
          <input
            type="text"
            value={propertyStatus.name.ru}
            onChange={handleInput}
            name="ru"
            required
          />
          <label htmlFor="iconType">Icon Type</label>
          <input
            type="text"
            value={propertyStatus.iconType}
            onChange={handleInput}
            name="iconType"
            required
          />
        </div>
        <button className="btn btn-primary">
          {isEditing ? "Edit" : "Submit"}
        </button>
      </form>
      <button className="btn btn-secondary mt-2" onClick={showData}>
        Show Data
      </button>
      <h5 className="text-center my-3">My Property Status</h5>
      <div className="table-responsive">
        <table className="table table-striped m-0">
          <thead>
            <tr>
              <th scope="col" className="text-center">
                №
              </th>
              <th scope="col">Name (az)</th>
              <th scope="col">Name (en)</th>
              <th scope="col">Name (ru)</th>
              <th scope="col">Icon Type</th>
              <th scope="col" className="text-center">
                Edit
              </th>
              <th scope="col" className="text-center">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {propertyStatusAdmin.map((type, index) => (
              <tr key={type.id}>
                <th scope="row" className="text-center">
                  {index + 1}
                </th>
                <td>{type.name.az}</td>
                <td>{type.name.en}</td>
                <td>{type.name.ru}</td>
                <td>{type.iconType}</td>
                <td className="text-center">
                  <button
                    className="btn btn-outline-warning me-3"
                    onClick={() => handleEdit(type)}
                  >
                    Edit
                  </button>
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => handleDelete(type.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PropertyStatusManager;
