import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserProfile from "../assets/icons/user_profile.svg";
import BackwardImage from "../assets/icons/Backward.svg";
import i18next from "i18next";
import { Select } from "antd";
const Header = ({ title, showProfile }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const toggleLang = (lang) => {
    i18next.changeLanguage(lang);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const handleBack = () => {
    navigate(-1);
  };
  const handleProfileClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/account");
    } else {
      navigate("/signup");
    }
  };

  return (
    <header className="mt-3 d-flex align-items-center justify-content-between mb-3">
      <button className="btn m-0 p-0" onClick={handleBack}>
        <img src={BackwardImage} alt="eprorab logo" title="eprorab" />
      </button>
      <div className="page-title ms-5">
        <h4>{title}</h4>
      </div>
      <div className="d-flex align-items-center">
        {" "}
        <Select
          className="language--change"
          defaultValue="en"
          style={{
            width: 60,
          }}
          onChange={toggleLang}
          options={[
            {
              value: "en",
              label: "Eng",
            },
            {
              value: "az",
              label: "Aze",
            },
            {
              value: "ru",
              label: "Ru",
            },
          ]}
        />
        {showProfile ? (
          <img
            src={UserProfile}
            alt="user profile"
            onClick={handleProfileClick}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <div className="ms-4"></div>
        )}
      </div>
    </header>
  );
};

export default Header;
