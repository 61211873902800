import React from "react";
import EntryLogo from "../../assets/images/EntryLogo.png";
import AzeFlag from "../../assets/icons/Aze.svg";
import EngFlag from "../../assets/icons/Eng.svg";
import RusFlag from "../../assets/icons/Rus.svg";
import { Link } from "react-router-dom";
import i18next from "i18next";
const Entry = () => {
  document.title = "Giriş";
  const toggleLang = (lang) => {
    i18next.changeLanguage(lang);
  };

  return (
    <div className="entry-section">
      <div className="top-section">
        <img src={EntryLogo} alt="" />
      </div>
      <div className="bottom-section d-flex flex-column gap-2 px-3">
        <Link
          className="text-decoration-none"
          to="/onboarding"
          onClick={() => toggleLang("az")}
        >
          <div className="az-section section d-flex align-items-center gap-3">
            <div className="flag-section">
              <img src={AzeFlag} alt="" />
            </div>
            <div className="text-section">
              <h4>Azərbaycan dili ilə davam edin</h4>
            </div>
          </div>
        </Link>
        <Link
          className="text-decoration-none"
          to="/onboarding"
          onClick={() => toggleLang("en")}
        >
          <div className="en-section section d-flex align-items-center gap-3">
            <div className="flag-section">
              <img src={EngFlag} alt="" />
            </div>
            <div className="text-section">
              <h4>Continue with English</h4>
            </div>
          </div>
        </Link>
        <Link
          className="text-decoration-none"
          to="/onboarding"
          onClick={() => toggleLang("ru")}
        >
          <div className="ru-section section d-flex align-items-center gap-3">
            <div className="flag-section">
              <img src={RusFlag} alt="" />
            </div>
            <div className="text-section">
              <h4>Продолжить с русским</h4>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Entry;
